import React, {useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { persistor, RootState } from '../store/store'
import { push, goBack } from 'redux-first-history'
import { setSessionInProgress } from '../store/user'
import { useTranslation } from 'react-i18next'

import useDiagnostic from '../hooks/useDiagnostic'
import { endCurrentSession } from './useHistoryData'


import {  NavButton, SelectedValueWrapper } from '../components/navComponents'
import { debounce } from '../utils'
import { openDTCModal } from '../store/application'
import {SESSION_STATUS} from "../utils/constants";

const useNavigation = () => {
  const dispatch = useDispatch()
  const [width, setWidth] = React.useState(window.innerWidth)
  const currentPage = useSelector((state: RootState) => state.router.location.pathname)
  const sessionInProgress = useSelector(state => state.user?.sessionInProgress)
  const { selectedDiagnosticItems, selectedSSDBSeries, selectedProduct, clean, startTime } = useDiagnostic()
  const [redirecting, setRedirecting] = useState<any>(false)
  const fullState = useSelector((state: RootState) => state)
  const { t } = useTranslation()

  const isSessionFinished = sessionInProgress?.meta?.status === SESSION_STATUS.FINISHED

  const navComponent = useMemo(() => {
    switch (currentPage) {
      case '/':  return null
      case '/selectModels':  return null
      case '/selectDtc': return !startTime && NavButton({ label: t('Edit Model'), onClick: () => dispatch(push('/selectModels')) })
      case '/causesTests': return NavButton({ label: t('Edit DTCs'), onClick: () => dispatch(push('/selectDtc')), disabled: isSessionFinished })
      default: return NavButton({ label: t('Back'), onClick: () => dispatch(goBack()) })
    }
  }, [currentPage, isSessionFinished])

  const selectDtc = (value: any) => {
    dispatch(openDTCModal(value))
  }

  const selectedItemsComponent = useMemo(() => {
    switch (currentPage) {
      case '/selectDtc': return SelectedValueWrapper({ values: [selectedSSDBSeries?.label, selectedProduct?.label] })
      case '/causesTests': return width > 500 && SelectedValueWrapper({
        values: selectedDiagnosticItems.map(v => v.label),
        selectedDiagnosticItems,
        selectDtc,
        disabled: isSessionFinished,
      })
      default: return
    }
  }, [currentPage, width, isSessionFinished, JSON.stringify(selectedDiagnosticItems)])


  const cancelSession = async () => {
    // deprecated - replaced by saveAndRestart
    // await cancelCurrentSession(fullState)
  }


  const saveAndRestart = async (diagnosticIdInProcess, saveToHistory = true, cleanStore = true) => {
    console.log('saveAndRestart', saveToHistory, fullState, 'clean store', cleanStore)
    await endCurrentSession(diagnosticIdInProcess, saveToHistory)

    if (cleanStore) {
      dispatch(clean())
      await persistor.purge()
    }

    dispatch(setSessionInProgress(null))
    dispatch(push('/selectModels'))
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth)
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  return {
    navComponent,
    selectedItemsComponent,
    width,
    currentPage,
    saveAndRestart,
    redirecting,
    cancelSession
  }
}


export default useNavigation
