import { Col, Divider, FlexboxGrid, Panel, Row } from 'rsuite'
import { Carousel } from 'react-responsive-carousel'
import CachedImage from '../cachedImage'
import { image, imageWithoutToken} from '../../utils/fetcher'
import { LinksBlock, ReferenceValues } from './index'
import { GreenGhostButton } from '../button'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RepairTab = ({ debug,  repairInfo, testData, onConductRepair }) => {
  const { t } = useTranslation()

  // const repairInstruction = repairInfo?.repairInstructionResourceIdTranslation || testData[0]?.pcDetailForTest?.repairInstructionResourceIdTranslation
  const repairInstruction = testData[0]?.pcDetailForTest?.repairInstructionResourceIdTranslation
  
  return (
    <div style={{ borderLeft: '1px solid #e5e5ea'}}>
      { debug && <pre>{JSON.stringify(repairInfo, null, 2)}</pre> }
      { debug && <pre>{JSON.stringify(testData, null, 2)}</pre> }
      <Panel>
        <p style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: repairInstruction }} />
      </Panel>
      <Divider style={{ margin: 0 }} />
      <Panel>
        {!!repairInfo.linksImg?.length &&
          <Carousel shape='bar' autoplay>
            {repairInfo.linksImg?.map((lm: any) => <img src={image(lm.image)} url={imageWithoutToken(lm.image)} />)}
          </Carousel>
        }
        {repairInfo.references?.map((r: any) =>
          <>
            <ReferenceValues>
              <b style={{ lineHeight: '40px' }}>{t('Related Parts')}</b>
              <FlexboxGrid style={{ marginTop: '8px' }} >
                { debug && <pre>{JSON.stringify(r, null, 2)}</pre> }
                {
                  [1, 2, 3, 4].filter(_ => r[`concernedParts${_}ResourceId`]).map(_ => (
                    <>
                      <FlexboxGrid.Item colspan={24}>
                        <b>{r[`concernedParts${_}ResourceIdTranslation`]}&nbsp;</b>
                        {r[`memo${_}ResourceId`] ? `(${r[`memo${_}ResourceIdTranslation`]})` : ``}
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item colspan={24}>
                        {t`Part number`}: {r[`pN${_}ResourceId`] ? `(${r[`pN${_}ResourceIdTranslation`]})` : `N/A`}
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item colspan={24}>&nbsp;</FlexboxGrid.Item>
                    </>
                  ))
                }
              </FlexboxGrid>
            </ReferenceValues>
          </>
        )}
      </Panel>
      <Divider style={{ margin: 0 }} />
      <Panel>
        {repairInfo.linksRef?.map((lr: any) => <LinksBlock link={lr} />)}
      </Panel>
      <Divider style={{ margin: 0 }} />
      <Panel>
        <Row>
          <Col xsOffset={6} xs={12}>
            <GreenGhostButton
              onClick={() => onConductRepair(testData?.[0])}
              width='100%'
            >
              {t`Conducted Repair`}
            </GreenGhostButton>
          </Col>
        </Row>
      </Panel>
      <br />
    </div>
  )
}

export default RepairTab
