import useDiagnostic from "../hooks/useDiagnostic";
import styled from "styled-components";
import InfoOutlineIcon from '@rsuite/icons/InfoOutline'
import {FlexboxGrid} from "rsuite";

const SymptomDescriptionBlock = styled.div`
  border: 1px solid #009999;
  padding: 10px;
  background-color: #cce2ff;
  border-radius: 5px;
  margin-bottom: 5px;
`

const SymptomDescription = () => {
  const { selectedDiagnosticItems } = useDiagnostic()
  const symptoms = selectedDiagnosticItems.filter(sdi => sdi.symptom)

  if (!symptoms.length) return null
  const selectedSymptom = selectedDiagnosticItems.find(sdi => sdi.isActive && sdi.symptom)

  if (!selectedSymptom || !selectedSymptom.symptom.descriptionResourceIdTranslation) return null

  const oneSymptomSelected = symptoms.length === 1

  return (
    <SymptomDescriptionBlock>
      <FlexboxGrid align='middle'>
        <FlexboxGrid.Item style={{ paddingRight: 10 }}>
          <InfoOutlineIcon height={20} width={20} fill='#009999' />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item>
          {!oneSymptomSelected ? <b>{selectedSymptom.label}:&nbsp;</b> : ''}
          <span dangerouslySetInnerHTML={{ __html: selectedSymptom.symptom.descriptionResourceIdTranslation}} />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </SymptomDescriptionBlock>
  )
}

export default SymptomDescription
