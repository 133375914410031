import React, {useCallback, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { pick } from 'ramda'
import { Grid, Panel, Row, Col, FlexboxGrid, Divider } from 'rsuite'
import FileDownloadIcon from '@rsuite/icons/FileUpload'
import {useDispatch, useSelector} from 'react-redux'
import Hotkeys from 'react-hot-keys'

import Button, { SecondaryButton } from '../components/button'
import Card from '../components/card'

import useDiagnostic, {restartDiagnostic} from '../hooks/useDiagnostic'
import useProductData from '../hooks/useProductData'
import useNavigation from '../hooks/useNavigation'
import PCList from '../components/pcList'
import SelectorDrawer from '../components/selectordrawer'
import {NavBtn, SelectedValueWrapper} from '../components/navComponents'
import {resolveDiagnosticItem, setPcList, cleanAnswers, setUserActivity} from '../store/diagnostic'
import {
  filterChildrenAndRoot,
  findElementById,
  findIdBeforeId,
  flatten,
  removeChildById,
  traverseAndModify
} from "../utils"
import {DRAWER_BODY, SESSION_STATUS, TEST_RESULT_BY, TEST_RESULTS} from '../utils/constants'
import { openDTCModal } from "../store/application";

import {
  DrawerBodyContent, DrawerFooterContent,
  PcListMobileLink,
} from '../components/causesAndTests'
import RepairTab from "../components/causesAndTests/repairTab";
import TestTab from "../components/causesAndTests/testTab";
import useHistoryData, {saveSessionStateToDb} from "../hooks/useHistoryData";
import PdfReport from "../components/pdfReport";
import {finishSession} from "../store/user";
import RateAppModal from "../components/rateAppModal";
import {sendMessage, setUpListeners} from "../utils/Kobd";

const CausesTests = () => {
  const fullState = useSelector(state => state)
  const isOnline = useSelector(state => state.application.connectionStatus) === 'online'
  const sessionInProgress = useSelector(state => state.user?.sessionInProgress)
  const user = useSelector(state => state.user)
  const configs = useSelector(state => state.application.configs)
  const {
    openedPC, openedTestRepairTab, setOpenedTestRepairTab, selectedSerialNumber, setActiveDiagnosticItems, selectedDiagnosticItems,
    setOpenedPC, setTestAnswers, getTestAnswersByPCAndTestId, geTestNotesByPCAndTestId, selectedDiagnosticItemsIds,
    setTestNotes, getTestResultByPc, notResolvedDiagnosticItems, setDtc, isPCFullyTested,
    testAnswers, keysToExpand, setKeysToExpand,
  }: any = useDiagnostic()
  const { width } = useNavigation()
  const {
    getPcListByDiagnosticItems, getTestForPc, diagnosticItemsTreeWithPc,
    getRepairInfoByPcId, diagnosticItemsBySelectedSeries, getNewTabHtml
  } = useProductData()
  const { exportDiagnosticJSON } = useHistoryData()

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [openRateAppModal, setOpenRateAppModal] = useState(false)
  const [open, setOpen] = useState<any>(false)
  const [debug, setDebug] = useState<any>(false)
  const [drawerBody, setDrawerBody] = useState<any>(DRAWER_BODY.ASK)
  const [areAllPcResolved, setAreAllPcResolved] = useState(false)
  const [areDIResolved, setAreDIResolved] = useState(false)
  const [PCListEmtpy, setPCListEmtpy] = useState(false)
  const [testData, setTestData] = useState<any>([])
  const [repairInfo, setRepairInfo] = useState<any>({})
  const [unusedDiagnosticItems, setUnusedDiagnosticItems] = useState(diagnosticItemsBySelectedSeries.map(di => filterChildrenAndRoot(di, selectedDiagnosticItemsIds)).filter(s => !!s))

  const pcBySelectedSeriesAndDTCs = getPcListByDiagnosticItems(null)

  const isSessionFinished = sessionInProgress?.meta?.status === SESSION_STATUS.FINISHED

  const findUnresolvedPcNode = pcNode => {
    if (!pcNode) return null

    const hasTest = !!getTestForPc(pcNode.pcId).length
    const resolved = getTestResultByPc(pcNode.pcId)?.result

    if (!hasTest && pcNode.children?.length) {
      return openChildPc(pcNode.pcId, false)
    } else if (hasTest && !resolved) {
      return pcNode.pcId
    }
  }

  const isPcResolved = pc => !pc.pcDetail?.testId ? true : isPCFullyTested(pc.pcId, pc.sdi?.id)

  const kitsUrl = () => {
    console.log('kitsUrl', process.env)
    const APP_ENV = process.env.REACT_APP_APP_ENV || 'uat'
    try {
      let link = ''
      const userRoles = [...user.currentUser.kitsRoles, ...user.currentUser.kiscRoles]
      const inquiryLinksConfig = configs.find(_ => _.key === 'inquiryLinks')
      const inquiryLinks = inquiryLinksConfig ? JSON.parse(inquiryLinksConfig.value) : []

      const matchingRole = inquiryLinks.find(_ => _.roles && _.roles.find(role => userRoles.includes(role)))
      console.log('matchingRole', matchingRole)
      if (matchingRole) link = matchingRole.link
      else {
        const defaultLink = inquiryLinks.find(_ => _.default)
        if (defaultLink) link = defaultLink.link
      }

      if (link) {
        if (link.includes('?')) return link + `&kid=${sessionInProgress._id}&env=${APP_ENV}`
        else return link += `?kid=${sessionInProgress._id}&env=${APP_ENV}`
      }

      return `https://kits.intra.kbt-global.com/new?technicalinquiry=&kid=${sessionInProgress._id}&env=${APP_ENV}`
    } catch (e) {
      console.error(e)
      return `https://kits.intra.kbt-global.com/new?technicalinquiry=&kid=${sessionInProgress._id}&env=${APP_ENV}`
    }

  }

  const checkPCsWith = fn => pcBySelectedSeriesAndDTCs
    .map(rootPc => {
      let resolved = []
      traverseAndModify(rootPc, pc => {
        console.log('checkPCsWith', pc, fn(pc), !!pc.pcDetail?.testId,  isPCFullyTested(pc.pcId, pc.sdi?.id))
        if (fn(pc)) {
          resolved.push(true)
        } else {
          resolved.push(false)
        }
      })
      return resolved
    })
    .flat()
    .every(r => r)


  const getNotAnsweredPC = (pcId = null) => {
    let pcNode

    const areAllAnswered = pcBySelectedSeriesAndDTCs
      .map(rootPc => {
        let resolved = []
        traverseAndModify(rootPc, pc => {
            if (getTestResultByPc(pc)) {
              resolved.push(true)
            } else {
              resolved.push(false)
            }
        })
        return resolved
      })
      .flat()
      .every(r => r)

    if (areAllAnswered) {
      return null
    }

    if (pcId) {
      const currentPcNode = getPcNodeById(pcId)
      const selectedNodeRoot = diagnosticItemsTreeWithPc.find(di => di.rootId === currentPcNode.rootId)
      const parentPcId = findIdBeforeId(selectedNodeRoot, currentPcNode.pcId, 'pcId').map(v => v.id)?.[0]
      const parentPcNode = getPcNodeById(parentPcId)
      if (parentPcNode?.children?.length) {
        const nonAnsweredPcId = openChildPc(parentPcNode)
        if (nonAnsweredPcId) {
          return nonAnsweredPcId
        }
      }
    }

    for (pcNode of pcBySelectedSeriesAndDTCs) {
      const unresolvedPc = findUnresolvedPcNode(pcNode)
      console.log('getNotAnsweredPC findUnresolvedPcNode', unresolvedPc)
      if (unresolvedPc) return unresolvedPc
    }

    return null
  }

  const getPcNodeById = pcId => {
    if (!pcId) return  {}

    return pcBySelectedSeriesAndDTCs.reduce((acc, rootPc) => {
      const foundPc = findElementById(rootPc, pcId, 'pcId')
      if (foundPc) {
        acc = foundPc
      }
      return acc
    }, {})
  }

  const openChildPc = (openedPC, open = false) => {
    const currentPcNode = getPcNodeById(openedPC)
    const selectedNodeRoot = diagnosticItemsTreeWithPc.find(di => di.rootId === currentPcNode.rootId)
    const childPcs = getPcListByDiagnosticItems(currentPcNode.rootId, currentPcNode.id)
    const onlyGroupping = childPcs.every(pc => !getTestForPc(pc.pcId).length)

    if (onlyGroupping && childPcs.length) {
      const idsToExpand = findIdBeforeId(selectedNodeRoot, childPcs[0].pcId, 'pcId').map(({ id }) => id)
      setKeysToExpand(idsToExpand)
      return openChildPc(childPcs[0].pcId)
    }

    const nonAnsweredChild = childPcs.filter(pc => !getTestResultByPc(pc.pcId)?.result && getTestForPc(pc.pcId).length)

    if (nonAnsweredChild.length) {
      const idsToExpand = findIdBeforeId(selectedNodeRoot, childPcs[0].pcId, 'pcId').map(({ id }) => id)

      setKeysToExpand(idsToExpand)
      console.log('openChildPc nonAnsweredChild', openedPC, currentPcNode, nonAnsweredChild)
      dispatch(setOpenedPC(nonAnsweredChild[0].pcId))
      dispatch(setOpenedTestRepairTab('test'))
      return nonAnsweredChild[0].pcId
    } else {
      return null
    }
  }

  const resolveParentPcByChild = currentPcNode => {
    const selectedNodeRoot = diagnosticItemsTreeWithPc.find(di => di.rootId === currentPcNode.rootId)
    const parentPcId = findIdBeforeId(selectedNodeRoot, currentPcNode.pcId, 'pcId').map(v => v.id)?.[0]
    const pc = findElementById(selectedNodeRoot, parentPcId, 'pcId')

    if (pc?.children?.length) {
      const allChildPcHaveAnswers = pc.children.every(pc => getTestResultByPc(pc.pcId))

      if (allChildPcHaveAnswers) {
        const allChildPcHaveAnswersPass = pc.children.every(pc => getTestResultByPc(pc.pcId).result === TEST_RESULTS.PASS)
        const allChildPcHaveAnswersFail = pc.children.every(pc => getTestResultByPc(pc.pcId).result === TEST_RESULTS.FAIL)
        const conductRepair = pc.children.some(pc => getTestResultByPc(pc.pcId).result === TEST_RESULTS.CONDUCT_REPAIR)

        if (allChildPcHaveAnswersPass)
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.PASS, testData.passOutcomeLabelResourceIdTranslation)
        else if (allChildPcHaveAnswersFail)
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.FAIL, testData.failOutcomeLabelResourceId)
        else if (conductRepair)
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.CONDUCT_REPAIR, testData.passOutcomeLabelResourceIdTranslation)
        else
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.TESTED, testData.passOutcomeLabelResourceIdTranslation)
      }
    }
  }

  const openNextPc = () => {
    const nextPc = getNotAnsweredPC(openedPC)
    dispatch(setOpenedTestRepairTab('test'))
    dispatch(setOpenedPC(nextPc))
    return nextPc
  }


  const onOpenPC = useCallback(({ value }) => {
    console.log('onOpenPC', value);
    const testData = getTestForPc(value);
    const currentPcNode = getPcNodeById(value);
    console.log('currentPcNode', currentPcNode, testData);

    if (!testData.length) {
      if (currentPcNode.children && currentPcNode.children.length) {
        openChildPc(value, true)
      }
    } else {
      setTestData(testData);
      dispatch(setOpenedPC(value));
      dispatch(setOpenedTestRepairTab('test'));
    }

    setOpen(false)

  }, [])

  const isTestFail = () => getTestResultByPc(openedPC)?.result === TEST_RESULTS.TESTED

  const onOpenRepairTab = () => {
    dispatch(setOpenedTestRepairTab('repair'))
  }

  const onAnswer = (result: string, testData: any) => {
    setTestResult(openedPC, testData.id, testData.testQuestionResourceIdTranslation, result, testData.passOutcomeLabelResourceIdTranslation)

    if (result == TEST_RESULTS.TESTED) {
      return dispatch(setOpenedTestRepairTab('repair'))
    }

    if (result === TEST_RESULTS.FAIL) {
      const currentPcNode = getPcNodeById(openedPC)
      if (currentPcNode?.children?.length) {
        openChildPc(openedPC, true)
      } else {
        openNextPc()
      }
    } else {
      openNextPc()
    }
  }

  const onResolveDiagnosticItem = item => {
    const test = testData?.[0]
    const currentPcNode = getPcNodeById(openedPC)
    console.log('resolvedDiagnosticItem onResolveDiagnosticItem', item, currentPcNode)
    dispatch(resolveDiagnosticItem({ ...item, diagnosticItemId: currentPcNode.id, pcId: openedPC, pcConcernedPart: `${currentPcNode.pc.concernedPartResourceIdTranslation} ${currentPcNode.pc.typeOfFailureResourceIdTranslation}` }))
    setUnusedDiagnosticItems(diagnosticItemsBySelectedSeries.map(di => filterChildrenAndRoot(di, selectedDiagnosticItemsIds)).filter(s => !!s))
    setTestResult(openedPC, test.id, test.testQuestionResourceIdTranslation, TEST_RESULTS.CONDUCT_REPAIR, test.passOutcomeLabelResourceIdTranslation)
  }

  const resolveRemainsDTCs = () => {
    const currentPcNode = getPcNodeById(openedPC)
    const test = testData?.[0]
    console.log('resolvedDiagnosticItem resolveRemainsDTCs', currentPcNode)
    setTestResult(openedPC, test.id, test.testQuestionResourceIdTranslation, TEST_RESULTS.CONDUCT_REPAIR, test.passOutcomeLabelResourceIdTranslation)
    notResolvedDiagnosticItems.map(di => dispatch(resolveDiagnosticItem({ ...di, diagnosticItemId: currentPcNode.id, pcId: currentPcNode.pcId, pcConcernedPart: `${currentPcNode.pc.concernedPartResourceIdTranslation} ${currentPcNode.pc.typeOfFailureResourceIdTranslation}` })))
  }

  const onAddToList = item => {
    setDtc('add', item)
    setUnusedDiagnosticItems(prevState =>
      !item.rootId
        ? prevState.filter(option => option.value !== item.value)
        : removeChildById(prevState, item.value, 'value')
    )
  }

  const setTestResult = (pcId: number, testId: number, testLabel: string, result: string, resultLabel: string, passedBy = TEST_RESULT_BY.USER) => {
    dispatch(setTestAnswers({ pcId, testId, testLabel, result, resultLabel, passedBy }))
  }

  const setTestNote = (testId: number, notes: string) => {
    dispatch(setTestNotes({ pcId: openedPC, testId, notes }))
  }

  const goToRepairTabWithoutTest = () => {
    dispatch(setOpenedTestRepairTab('repair'))
    setOpen(false)
  }

  const onConductRepair = () => {
    setDrawerBody(DRAWER_BODY.ASK)
    setOpen(true)
  }

  useEffect(() => {
    pcBySelectedSeriesAndDTCs.forEach(rootPc => {
      traverseAndModify(rootPc, pc => {
        resolveParentPcByChild(pc)
      })
    })

    if (!pcBySelectedSeriesAndDTCs.length) {
      setPCListEmtpy(true)
      return
    }

    if (!notResolvedDiagnosticItems.length) setAreDIResolved(true)
    else setAreDIResolved(false)

    const arePCresolved = checkPCsWith(isPcResolved)

    if (arePCresolved) setAreAllPcResolved(true)
    else setAreAllPcResolved(false)
  },
    [JSON.stringify(testAnswers), notResolvedDiagnosticItems.length]
  )

  // open next PC
  useEffect(() => {
    if (getTestResultByPc(openedPC)?.result === TEST_RESULTS.TESTED || drawerBody === DRAWER_BODY.PARTIALLY) return
    openNextPc()
  }, [JSON.stringify(testAnswers)])

  useEffect(() => {
    if (openedPC) {
      const currentPcNode = getPcNodeById(openedPC)
      const constSDIids = currentPcNode.roots?.map(({ sdi }) => sdi.id)
      setTestData(getTestForPc(openedPC))
      setRepairInfo(getRepairInfoByPcId(openedPC))
      dispatch(setActiveDiagnosticItems(constSDIids))
    } else {
      openNextPc()
    }
  }, [openedPC])

  useEffect(() => {
    if ((areDIResolved || areAllPcResolved || PCListEmtpy) && !isSessionFinished) {
      dispatch(finishSession())

      if (isOnline) setTimeout(() => setOpenRateAppModal(true), 3000)

      saveSessionStateToDb(fullState, SESSION_STATUS.FINISHED)
    }
  }, [areDIResolved, areAllPcResolved, PCListEmtpy])


  useEffect(() => {
    if (openedPC) {
      setTestData(getTestForPc(openedPC))
      setRepairInfo(getRepairInfoByPcId(openedPC))
    }
    return () => {
      setOpenedPC(null)
      setTestData(null)
      setRepairInfo({})
    }
  }, [i18n.language])

  const queryParams = new URLSearchParams(window.location.search)
  const isKobd = (queryParams.get('kobd') === '1') || (queryParams.get('kobd') === 'true') || (queryParams.get('restore') === 'true')  || (queryParams.get('restore') === '1')

  useEffect(() => {
    const pcListFlatten = flatten(pcBySelectedSeriesAndDTCs)
    console.log('pcListFlatten', pcListFlatten)
    dispatch(setPcList(pcListFlatten.map(pc => pick(['id', 'parent', 'pcId', 'pc', 'sdi'], pc))))
    if (isKobd) {
      const autoDiagItems = selectedDiagnosticItems.filter(item => item.autoDiag && item.autoDiagKey)
      const AutoDiagFailedStatus = JSON.parse(configs.find(_ => _.key === 'AutoDiagFailedStatus').value)

      const handleIncomingAutoDiagMessage = incomingData => {
        if (incomingData.data?.source === 'KOBD' && incomingData.data?.Function?.[0].Function === 'AutoDiag') {
          console.log('handleIncomingAutoDiagMessage', incomingData.data)
          pcListFlatten.forEach(pc => {
            //console.log('handleIncomingAutoDiagMessage pc', pc)
            if (pc.autoDiag && (pc.sdi.autoDiagKey === incomingData.data?.Function?.[0]?.Key)) {
              const incomingFactor =  incomingData.data?.Function?.[0]?.Factor
              const testData = getTestForPc(pc.pcId)?.[0]
              console.log('handleIncomingAutoDiagMessage', testData)
              console.log('handleIncomingAutoDiagMessage 2', incomingFactor, testData, testData.id, testData.testQuestionResourceIdTranslation, testData.passOutcomeLabelResourceIdTranslation, testData.failOutcomeLabelResourceIdTranslation)
              if (incomingFactor === pc.autoDiagKey) {
                console.log('handleIncomingAutoDiagMessage 00',
                  pc.pcId,
                  testData.id,
                  testData.testQuestionResourceIdTranslation,
                  TEST_RESULTS.PASS,
                  testData.passOutcomeLabelResourceIdTranslation,
                  TEST_RESULT_BY.AUTO_DIAG)
                setTestResult(
                  pc.pcId,
                  testData.id,
                  testData.testQuestionResourceIdTranslation,
                  TEST_RESULTS.PASS,
                  testData.passOutcomeLabelResourceIdTranslation,
                  TEST_RESULT_BY.AUTO_DIAG
                )
              } else {
                if (AutoDiagFailedStatus.includes(incomingFactor) || incomingFactor === null || incomingFactor === 'null') {
                  console.log('handleIncomingAutoDiagMessage 11')
                  setTestResult(pc.pcId, testData.id, testData.testQuestionResourceIdTranslation, TEST_RESULTS.FAIL, testData.failOutcomeLabelResourceIdTranslation, TEST_RESULT_BY.AUTO_DIAG)
                }
              }

            }
          })
        }
      }
      setUpListeners(handleIncomingAutoDiagMessage)

      autoDiagItems.forEach(autoDiagItem => {
        sendMessage({
          Function: [{
            Function: 'AutoDiag',
            XCU: null,
            Key: autoDiagItem.autoDiagKey
          }],
          source: 'KID'
        })
      })
    }
  },
    [selectedDiagnosticItemsIds.join(',')]
  )

  const isFullDrawer = width < 992 && (drawerBody === DRAWER_BODY.PARTIALLY || drawerBody === DRAWER_BODY.PC_LIST)
  const disabledInterface = areDIResolved || PCListEmtpy

  return (
    <Hotkeys
      keyName='alt+d'
      onKeyUp={() => setDebug(!debug)}
    >
      {debug && <Button onClick={() => dispatch(cleanAnswers())}>clean</Button>}
      <RateAppModal open={openRateAppModal} />
      <Grid style={{ height: '100%' }}>
        <SelectorDrawer
          isOpen={open}
          onClose={() => {
            setOpen(false)
            setDrawerBody(DRAWER_BODY.ASK)
          }}
          isFull={isFullDrawer}
          size={drawerBody === DRAWER_BODY.PC_LIST ? 'lg' :'xs' }
          body={
            <DrawerBodyContent
              disabled={disabledInterface}
              drawerBody={drawerBody}
              testData={testData}
              notResolvedDiagnosticItems={notResolvedDiagnosticItems}
              openedPC={openedPC}
              pcBySelectedSeriesAndDTCs={pcBySelectedSeriesAndDTCs}
              unusedDiagnosticItems={unusedDiagnosticItems}
              keysToExpand={keysToExpand}
              setDrawerBody={setDrawerBody}
              setOpen={setOpen}
              setOpenedTestRepairTab={setOpenedTestRepairTab}
              onAnswer={onAnswer}
              onOpenPC={onOpenPC}
              onAddToList={onAddToList}
              onResolveDiagnosticItem={onResolveDiagnosticItem}
              resolveRemainsDTCs={resolveRemainsDTCs}
              goToRepairTabWithoutTest={goToRepairTabWithoutTest}
              getNewTabHtml={getNewTabHtml}
            />
          }
          hasFooter={drawerBody === DRAWER_BODY.PC_LIST}
          footer={
            <DrawerFooterContent
              drawerBody={drawerBody}
              exportDiagnosticJSON={() => {
                dispatch(setUserActivity({ type: 'EXPORT_PROJECT', time: new Date().getTime() }))
                exportDiagnosticJSON()
              } }
              kitsUrl={kitsUrl()}
              onInquiryClick={() => dispatch(setUserActivity({
                type: 'CREATE_INQUIRY',
                time: new Date().getTime()
              }))}
            />
        }
      />
        <Card style={{ height: '100%' }}>
          <Row gutter={0} style={{ height: '100%' }}>
            <Col xs={24} sm={24} mdHidden lgHidden>
              <NavBtn>
                <PcListMobileLink onClick={
                  () => {
                    setDrawerBody(DRAWER_BODY.PC_LIST)
                    setOpen(true)
                  }}
                />
              </NavBtn>
            </Col>
            <Col xs={24} sm={24} md={disabledInterface ? 24 : 14} mdPush={disabledInterface ? 0 : 10}>
              {!disabledInterface &&
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={isTestFail() ? 12 : 24}
                                    style={{ borderLeft: '1px solid rgb(229, 229, 234)' }}>
                    <NavBtn page='test' isActive={openedTestRepairTab === 'test'}
                            onClick={() => dispatch(setOpenedTestRepairTab('test'))}>{t('Test')}</NavBtn>
                  </FlexboxGrid.Item>
                  {isTestFail() &&
                    <FlexboxGrid.Item colspan={12}>
                      <NavBtn page='repair' isActive={openedTestRepairTab === 'repair'}
                              onClick={() => onOpenRepairTab()}>{t('Repair')}</NavBtn>
                    </FlexboxGrid.Item>
                  }
                </FlexboxGrid>
              }
              {disabledInterface && <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Panel>
                  <h6>{t(
                    areDIResolved
                      ? 'All DTC/Symptoms have been resolved'
                      : PCListEmtpy
                        ? 'We currently do not have information on the possible causes for the selected DTCs. However, we plan to provide this information in the future. Thank you for your understanding.'
                        : 'All Possible Causes have been tested')}
                  </h6>
                  {!areDIResolved &&
                    <div style={{ marginBottom: 15 }}>
                      <p>{t`Those DTC/Symptoms are not resolved:`}</p>
                      <br />
                      <SelectedValueWrapper selectedDiagnosticItems={notResolvedDiagnosticItems} selectDtc={v => dispatch(openDTCModal(v))} />
                    </div>
                  }
                  <p>{t('If you have found the cause for these DTCs, let us know. Your feedback will be used to improve the accuracy of Kubota intelligent Diagnostics.')}</p>
                  <div style={{ marginTop: 10 }}/>
                  <Button onClick={restartDiagnostic}>{t`Restart Diagnostic`}</Button>
                  <Divider/>
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {!selectedSerialNumber &&
                        <>
                          <p>
                            <b>{t`Please note: The report cannot be saved without a valid serial number. Enter the serial number to ensure it can be viewed in the Diagnostic History.`}</b>
                          </p>
                        </>
                      }
                      <div style={{ marginTop: 10 }}/>
                      <PdfReport />
                    </div>
                  }
                </Panel>
              </div>}
              <div>
                {!disabledInterface && openedTestRepairTab === 'test' && testData.map(td =>
                  <TestTab
                    key={td.testId}
                    debug={debug}
                    testData={td}
                    pcData={getPcNodeById(openedPC)}
                    geTestNotesByPCAndTestId={geTestNotesByPCAndTestId}
                    getTestAnswersByPCAndTestId={getTestAnswersByPCAndTestId}
                    setTestNote={setTestNote}
                    openedPC={openedPC}
                    onAnswer={onAnswer}
                  />)
                }
                {!disabledInterface && openedTestRepairTab === 'repair' &&
                  <RepairTab
                    debug={debug}
                    repairInfo={repairInfo}
                    testData={testData}
                    onConductRepair={onConductRepair}
                  />
                }
              </div>
            </Col>
            {!disabledInterface &&
              <Col xsHidden smHidden md={10} mdPull={14} style={{ height: '100%', borderRight: '1px solid #ccc' }}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <PCList
                    keysToExpand={keysToExpand}
                    onExpand={expandedKeys => setKeysToExpand(expandedKeys)}
                    debug={debug}
                    onOpenPC={onOpenPC}
                    openedPC={openedPC}
                    pcBySelectedSeriesAndDTCs={pcBySelectedSeriesAndDTCs}
                    disabled={disabledInterface}
                  />
                  <div style={{ paddingBottom: 20, textAlign: 'center' }}>
                    <Divider/>
                    <PdfReport withButton={false} previewBtn />
                    <div style={{ marginTop: 10 }}/>
                    <SecondaryButton onClick={() => {
                      dispatch(setUserActivity({ type: 'EXPORT_PROJECT', time: new Date().getTime() }))
                      exportDiagnosticJSON()
                    }}>
                      Export project file <FileDownloadIcon/>
                    </SecondaryButton>
                    <div style={{ marginTop: 10 }}/>
                    <a href={kitsUrl()} target='_blank' rel='noreferrer'>
                      <SecondaryButton onClick={() => dispatch(setUserActivity({
                        type: 'CREATE_INQUIRY',
                        time: new Date().getTime()
                      }))}>
                        Create an inquiry
                      </SecondaryButton>
                    </a>
                  </div>
                </div>
              </Col>
            }
          </Row>
        </Card>
      </Grid>
    </Hotkeys>
  )
}

export default CausesTests
