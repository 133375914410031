import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { intervalToDuration } from 'date-fns'
import { persistor, RootState, store } from '../store/store'
import {
  setStartTime,
  setSSDBSeries,
  setSeries,
  setProduct,
  setSerial,
  addDtc,
  removeDtc,
  cleanAllDtc,
  resolveDiagnosticItem,
  setOpenedPC,
  setActiveDiagnosticItems,
  setOpenedTestRepairTab,
  setAnswerToQuestion,
  setTestReferenceValue,
  setTestAnswers,
  setTestNotes,
  setKeysToExpand,
  reInitiateDiagnostic,
  clean,
  setPin
} from '../store/diagnostic'
import { SelectData } from '../components/selectorlist'
import { push } from 'redux-first-history'

import { camelCaseToWords, capitalizeFirstLetter } from '../utils'
import { getDataByKey } from "./useProductData";
import {setSessionInProgress} from "../store/user";
import {TEST_RESULTS_FULLY} from "../utils/constants";

const isNullOrUndefined = (value: any) => R.isNil(value) || R.isEmpty(value)
const equals = (a: any, b: any) => {
  if (isNullOrUndefined(a) && isNullOrUndefined(b)) return true
  return a === b
}
const compareProductGroup = (pg: any, matchingProductGroup: any) => {
  if (!pg || !matchingProductGroup) return false
  return equals(pg.productModel, matchingProductGroup['Product Model'])
    && equals(pg.spec1, matchingProductGroup['Spec 1'])
    && equals(pg.spec2, matchingProductGroup['Spec 2'])
    && equals(pg.spec3, matchingProductGroup['Spec 3'])
    && equals(pg.spec4, matchingProductGroup['Spec 4'])
    && equals(pg.spec5, matchingProductGroup['Spec 5'])
}

const useDiagnostic = () => {
  const dispatch = useDispatch()
  const startTime = useSelector((state: RootState) => state.diagnostic.startTime)
  const endTime = useSelector((state: RootState) => state.diagnostic.endTime)
  const selectedSeries = useSelector((state: RootState) => state.diagnostic.selectedSeries)
  const selectedSSDBSeries = useSelector((state: RootState) => state.diagnostic.selectedSSDBSeries)
  const selectedProduct = useSelector((state: RootState) => state.diagnostic.selectedProduct)
  const selectedSerialNumber = useSelector((state: RootState) => state.diagnostic.selectedSerialNumber)
  const selectedPin = useSelector((state: RootState) => state.diagnostic.selectedPin)
  const selectedDiagnosticItems = useSelector((state: RootState) => state.diagnostic.selectedDiagnosticItems)
  const notResolvedDiagnosticItems = selectedDiagnosticItems.filter(di => di.status !== 'resolved')
  const resolvedDiagnosticItems = selectedDiagnosticItems.filter(di => di.status === 'resolved')
  const answersToQuestion = useSelector((state: RootState) => state.diagnostic.answersToQuestion)
  const openedPC = useSelector((state: RootState) => state.diagnostic.openedPC)
  const openedTestRepairTab = useSelector((state: RootState) => state.diagnostic.openedTestRepairTab)
  const referencesValues = useSelector((state: RootState) => state.diagnostic.testReferenceValues)
  const testAnswers = useSelector((state: RootState) => state.diagnostic.testAnswers)
  const testNotes = useSelector((state: RootState) => state.diagnostic.testNotes)
  const testPhotos = useSelector((state: RootState) => state.diagnostic.testPhotos)
  const memo = useSelector((state: RootState) => state.diagnostic.memo)
  const keysToExpand = useSelector((state: RootState) => state.diagnostic.keysToExpand)
  const lastLoadedOrSavedDiagnostic = useSelector((state: RootState) => state.diagnostic.lastLoadedOrSavedDiagnostic)
  const diagnosticTime = (endTime && startTime && intervalToDuration({ end: endTime, start: startTime })) || {}
  const logs = useSelector((state: RootState) => state.diagnostic.logs)
  const sortWeights = useSelector((state: RootState) => state.application.sortWeights)
  const initialData = useSelector((state: RootState) => state.diagnostic.initialData)
  const sessionInProgress = useSelector((state: RootState) => state.user?.sessionInProgress)
  const pins = useSelector((state: RootState) => state.application?.pins)
  const configs = useSelector((state: RootState) => state.application?.configs)

  const getQuestionAnswer = (pcId: number, questionId: number) => answersToQuestion.find((aq) => aq.pcId === pcId && aq.questionId === questionId)
  const getTestAnswersByPCAndTestId = (pcId: number, testId: number) => testAnswers.find((ta: any) => ta.testId === testId && ta.pcId === pcId)
  const geTestNotesByPCAndTestId = (pcId: number, testId: number) => testNotes.find((ta: any) => ta.testId === testId && ta.pcId === pcId)
  const geTestPhotos = (pcId: number) => testPhotos.find((tp: any) => tp.pcId === pcId)?.photos || []
  const getTestResultByPc = (pcId: number) => testAnswers.find((ta: any) => ta.pcId === pcId && ta.result)
  const isTestPassedByResolvedDiagnosticItem = (diId: number) => resolvedDiagnosticItems.find(resolvedDI => resolvedDI.id === diId)

  const isPCFullyTested = (pcId: number, relatedSdiId: number) =>
    Object.values(TEST_RESULTS_FULLY).includes(getTestResultByPc(pcId)?.result)
      || !!isTestPassedByResolvedDiagnosticItem(relatedSdiId)

  const setSelector = (type: string, value: any) => {
    switch (type) {
      case '_s_s_d_b_series': {
        console.log('setSelector :: ssdb series', value)
        dispatch(setProduct(null))
        selectedDiagnosticItems.length && dispatch(cleanAllDtc())
        dispatch(setSSDBSeries(value))
        // const series = getDataByKey('series').find(s => s.id === value.value)
        const series = getDataByKey('series').find(s => s.id === value.item?.seriesId) // KID-97
        if (series) dispatch(setSeries({ label: series.name, value: series.id }))
        else dispatch(setSeries(null))
        break
      }
      case 'product_group': {
        console.log('v', value)
        dispatch(setProduct(value))
        break
      }
      case 'serialNumber': {
        return dispatch(setSerial(value))
      }
      case 'pin': {
        console.log('pins', pins, value)
        const matchingPin = pins.find((_: any) => _.BIDN === value)
        console.log('pin mapping - matchingPin', matchingPin)
        let productGroupFound = false
        if (matchingPin) {
          console.log('pin mapping - matchingPin', matchingPin)
          const modelCodeProductGroupMappingData = configs && configs && configs.find(_ => _.key === 'modelCodeProductGroupMapping')
          let modelCodeProductGroupMapping
          if (modelCodeProductGroupMappingData && modelCodeProductGroupMappingData.value) {
            modelCodeProductGroupMapping = JSON.parse(modelCodeProductGroupMappingData.value)
          }
          const matchingProductGroup = modelCodeProductGroupMapping.find((_: any) => _.model_code === matchingPin.BMCMDLCDF)
          console.log('pin mapping - matchingProductGroup', matchingProductGroup)
          if (matchingProductGroup) {
            const currentSsdbSeries = getDataByKey('_s_s_d_b_series').find((s: any) => s.name === matchingProductGroup['SSDB Series'])
            console.log('pin mapping - currentSsdbSeries', currentSsdbSeries)
            if (currentSsdbSeries && currentSsdbSeries.id) {
              dispatch(setSSDBSeries({
                label: currentSsdbSeries.name,
                value: currentSsdbSeries.seriesId,
                item: {
                  id: currentSsdbSeries.id,
                  name: currentSsdbSeries.name,
                  seriesId: currentSsdbSeries.seriesId,
                  ssdbId: currentSsdbSeries.ssdbId
                }
              }))

              const currentSeries = getDataByKey('series').find((s: any) => s.id === currentSsdbSeries.seriesId)
              if (currentSeries && currentSeries.id) {
                dispatch(setSeries({ label: currentSeries.name, value: currentSeries.id }))

                const productGroups = getDataByKey('product_group')
                console.log('productGroups', productGroups)
                const currentProductGroup = productGroups.find((pg: any) => {
                  return compareProductGroup(pg, matchingProductGroup)
                })

                console.log('currentProductGroup', currentProductGroup)
                if (currentProductGroup && currentProductGroup.id) {
                  productGroupFound = true
                  store.dispatch(setProduct({
                    label: currentProductGroup.name,
                    value: currentProductGroup.id,
                    item: currentProductGroup
                  }))
                }
              }
            }
          }
        }
        if (value.length >= 5) {
          dispatch(setSerial(value.slice(-5)))
        }
        dispatch(setPin(value))
        // if (productGroupFound) dispatch(push('/selectDtc'))
      }
    }
  }
  const setDtc = (action: string, item: SelectData) => {
    switch (action) {
      case 'add':
        return dispatch(addDtc(item))
      case 'remove':
        return dispatch(removeDtc(item))
    }
  }

  const setInitialData = data => dispatch(setInitialData(data))

  const addAnswerToQuestion = (pcId: number, questionId: number, questionLabel: string, button: string, buttonLabel: string) => dispatch(setAnswerToQuestion({ pcId, questionId, questionLabel, button, buttonLabel }))
  const addTestReferenceValue = (pcId: number, testId: number, commonId: number, optionId: number, value: number, isOk: boolean, type: string, category: string, valueText: string) => dispatch(setTestReferenceValue({ pcId, testId, commonId, optionId, value, isOk, type,  category, valueText }))
  const getReferenceValueById = (pcId: number, testId: number, commonId: number, optionId: number) => referencesValues.find((rf: any) => rf.pcId === pcId && rf.testId === testId && rf.commonId === commonId && rf.optionId === optionId)
  const getReferenceType = (pcId: number, testId: number, commonId: number) => referencesValues.find((rf: any) => rf.pcId === pcId && rf.testId === testId && rf.commonId === commonId)?.type

  const cleanDiagnostic = () => {
    dispatch(reInitiateDiagnostic())
  }

  const startDiagnostic = (startFrom: string) => {
    dispatch(setStartTime(new Date().getTime()))
    dispatch(push(startFrom))
  }

  const saveDiagnostic = async () => {
    await persistor.purge()
    store.dispatch(push('/selectsModels'))
    store.dispatch(clean())
  }

  const getTimeLineData = () => {
    const actionsNames = [
      'diagnostic/setSeries',
      'diagnostic/setModel',
      'diagnostic/setProduct',
      'diagnostic/addDtc',
      'diagnostic/removeDtc',
      'diagnostic/resolveDiagnosticItem',
      'diagnostic/setTestReferenceValue',
      'diagnostic/setTestAnswers'
    ]

    const getReadableDesc = (actionType, payload) => {
      if (!payload) return ''
      switch (actionType) {
        case 'diagnostic/setSeries':
        case 'diagnostic/setProduct':
        case 'diagnostic/addDtc':
        case 'diagnostic/removeDtc':
          return payload.label
        case 'diagnostic/resolveDiagnosticItem':
          return `${payload.label} Cause: ${payload.pcConcernedPart}`
        case 'diagnostic/setTestAnswers':
          const { testLabel, resultLabel } = getTestAnswersByPCAndTestId(payload.pcId, payload.testId)
          return `${testLabel || 'NONE'} ${resultLabel || 'NONE'}`
        case 'diagnostic/setTestReferenceValue':
          const { value, type } = payload
          return `${value} ${type}`
        default: return 'No Description'
      }
    }

    const actionsToDisplay = logs.filter(({ type }) => actionsNames.includes(type))

    return actionsToDisplay.map((log, i) => {
      const selectedProduct = actionsToDisplay.slice(0, i + 1).filter(({ type }) => type === 'diagnostic/setProduct').reverse()?.[0]

      return {
          date: new Date(log.date).toLocaleDateString() + '  '  + new Date(log.date).toLocaleTimeString(),
          product: selectedProduct?.payload?.label || 'NOT SELECTED',
          action: capitalizeFirstLetter(camelCaseToWords(log.type)),
          description: getReadableDesc(log.type, log.payload)
        }
      })
  }

  return {
    clean,
    startDiagnostic,
    cleanDiagnostic,
    startTime,
    diagnosticTime,
    selectedSSDBSeries,
    selectedSeries,
    selectedProduct,
    selectedSerialNumber,
    selectedPin,
    selectedDiagnosticItems,
    selectedDiagnosticItemsIds: R.pluck('id', selectedDiagnosticItems),
    notResolvedDiagnosticItems,
    resolvedDiagnosticItems,
    answersToQuestion,
    openedPC,
    testAnswers,
    memo,
    lastLoadedOrSavedDiagnostic,
    openedTestRepairTab,
    getTestAnswersByPCAndTestId,
    getQuestionAnswer,
    geTestPhotos,
    geTestNotesByPCAndTestId,
    getReferenceValueById,
    getReferenceType,
    isTestPassedByResolvedDiagnosticItem,
   // getTestNamesAndResults,
    getTimeLineData,
    getTestResultByPc,
    setSelector,
    setDtc,
    resolveDiagnosticItem: item => dispatch(resolveDiagnosticItem(item)),
    addAnswerToQuestion,
    addTestReferenceValue,
    setOpenedPC,
    setActiveDiagnosticItems,
    setOpenedTestRepairTab,
    setTestAnswers,
    setTestNotes,
    keysToExpand,
    setKeysToExpand: keys => dispatch(setKeysToExpand(keys)),
    saveDiagnostic,
    sortWeights,
    initialData,
    setInitialData: data => dispatch(setInitialData(data)),
    sessionInProgress,
    isPCFullyTested
  }
}

export const restartDiagnostic = async ({ initialData = {}}: any = {}) => {
  console.log('restartDiagnostic', initialData)
  await persistor.purge()
  await store.dispatch(push('/selectModels'))
  store.dispatch(clean())
  store.dispatch(setSessionInProgress (null))
  if (initialData && initialData.pin) {
    store.dispatch(setPin(initialData.pin))
    if (initialData.pin.length >= 5) store.dispatch(setSerial(initialData.pin.slice(-5)))
  }
  if (initialData && initialData.initialProductGroup) {
    console.log('initalProductGroup', initialData.initialProductGroup)

    const currentSsdbSeries = getDataByKey('_s_s_d_b_series').find((s: any) => s.name === initialData.initialProductGroup['SSDB Series'])
    if (currentSsdbSeries && currentSsdbSeries.id) {
      await store.dispatch(setSSDBSeries({
        label: currentSsdbSeries.name,
        value: currentSsdbSeries.seriesId,
        item: {
          id: currentSsdbSeries.id,
          name: currentSsdbSeries.name,
          seriesId: currentSsdbSeries.seriesId,
          ssdbId: currentSsdbSeries.ssdbId
        }
      }))
    }

    const currentSeries = getDataByKey('series').find((s: any) => s.id === currentSsdbSeries.seriesId)
    if (currentSeries && currentSeries.id) {
      await store.dispatch(setSeries({ label: currentSeries.name, value: currentSeries.id }))
    }

    const productGroups = getDataByKey('product_group')
    console.log('productGroups', productGroups)
    const currentProductGroup = productGroups.find((pg: any) => {
      // return pg.productModel === initialData.initialProductGroup['Product Model']
      return compareProductGroup(pg, initialData.initialProductGroup)
    })

    if (initialData && initialData.sn) {
      await store.dispatch(setSerial(initialData.sn))
    }

    if (currentProductGroup && currentProductGroup.id) {
      await store.dispatch(setProduct({
        label: currentProductGroup.name,
        value: currentProductGroup.id,
        item: currentProductGroup
      }))
      store.dispatch(push('/selectDtc'))
    }
  }

  // KITS Integration - series form URL
  if (initialData && initialData.series) {
    console.log('initialData.series', getDataByKey('_s_s_d_b_series'),getDataByKey('series'))
    const initialSsdbSeries = getDataByKey('_s_s_d_b_series').find((s: any) => s.name === initialData.series)
    console.log('initialSsdbSeries', initialSsdbSeries)
    if (initialSsdbSeries && initialSsdbSeries.id) {
      await store.dispatch(setSSDBSeries({
        label: initialSsdbSeries.name,
        value: initialSsdbSeries.seriesId,
        item: {
          id: initialSsdbSeries.id,
          name: initialSsdbSeries.name,
          seriesId: initialSsdbSeries.seriesId,
          ssdbId: initialSsdbSeries.ssdbId
        }
      }))

      const initialSeries = getDataByKey('series').find((s: any) => s.id === initialSsdbSeries.seriesId)
      console.log('initialSeries', initialSeries)
      if (initialSeries && initialSeries.id) {
        await store.dispatch(setSeries({ label: initialSeries.name, value: initialSeries.id }))
      }

    }
  }
}

export const initDiagnostic = async ({ initialData = {}}: any = {}) => {
  // new method on top of restartDiagnostic - we need to trigger setSeries even if params have not changed
  if (initialData && initialData.pin) {
    store.dispatch(setPin(initialData.pin))
    if (initialData.pin.length >= 5) store.dispatch(setSerial(initialData.pin.slice(-5)))
  }
  if (initialData && initialData.initialProductGroup) {
    console.log('initalProductGroup', initialData.initialProductGroup)

    const currentSsdbSeries = getDataByKey('_s_s_d_b_series').find((s: any) => s.name === initialData.initialProductGroup['SSDB Series'])
    if (currentSsdbSeries && currentSsdbSeries.id) {
      await store.dispatch(setSSDBSeries({
        label: currentSsdbSeries.name,
        value: currentSsdbSeries.seriesId,
        item: {
          id: currentSsdbSeries.id,
          name: currentSsdbSeries.name,
          seriesId: currentSsdbSeries.seriesId,
          ssdbId: currentSsdbSeries.ssdbId
        }
      }))
    }

    const currentSeries = getDataByKey('series').find((s: any) => s.id === currentSsdbSeries.seriesId)
    if (currentSeries && currentSeries.id) {
      await store.dispatch(setSeries({ label: currentSeries.name, value: currentSeries.id }))
    }

    const productGroups = getDataByKey('product_group')
    console.log('productGroups', productGroups)
    const currentProductGroup = productGroups.find((pg: any) => {
      // return pg.productModel === initialData.initialProductGroup['Product Model']
      return compareProductGroup(pg, initialData.initialProductGroup)
    })

    if (initialData && initialData.sn) {
      await store.dispatch(setSerial(initialData.sn))
    }

    if (currentProductGroup && currentProductGroup.id) {
      await store.dispatch(setProduct({
        label: currentProductGroup.name,
        value: currentProductGroup.id,
        item: currentProductGroup
      }))
      store.dispatch(push('/selectDtc'))
    }
  }

  // KITS Integration - series form URL
  if (initialData && initialData.series) {
    console.log('initialData.series', getDataByKey('_s_s_d_b_series'),getDataByKey('series'))
    const initialSsdbSeries = getDataByKey('_s_s_d_b_series').find((s: any) => s.name === initialData.series)
    console.log('initialSsdbSeries', initialSsdbSeries)
    if (initialSsdbSeries && initialSsdbSeries.id) {
      await store.dispatch(setSSDBSeries({
        label: initialSsdbSeries.name,
        value: initialSsdbSeries.seriesId,
        item: {
          id: initialSsdbSeries.id,
          name: initialSsdbSeries.name,
          seriesId: initialSsdbSeries.seriesId,
          ssdbId: initialSsdbSeries.ssdbId
        }
      }))

      const initialSeries = getDataByKey('series').find((s: any) => s.id === initialSsdbSeries.seriesId)
      console.log('initialSeries', initialSeries)
      if (initialSeries && initialSeries.id) {
        await store.dispatch(setSeries({ label: initialSeries.name, value: initialSeries.id }))
      }

    }
  }
}

export default useDiagnostic
