export const version = '2.0.8'
export const cacheName = 'kid'
export const localImagesCache = 'localImages'

export const TEST_RESULTS = {
  TESTED: 'tested',
  PASS: 'pass',
  FAIL: 'fail',
  CONDUCT_REPAIR: 'conduct_repair',
  RESOLVED: 'resolved'
}

export const TEST_RESULT_BY = {
  USER: 'user',
  AUTO_DIAG: 'auto_diag'
}

export const TEST_RESULTS_FULLY = {
  PASS: 'pass',
  FAIL: 'fail',
  RESOLVED: 'resolved'
}

export const SESSION_STATUS = {
  FINISHED: 'finished',
  IN_PROGRESS: 'inProgress'
}

export const DRAWER_BODY = {
  ASK: 'ask',
  PC_LIST: 'pcList',
  PARTIALLY: 'partially',
  TEST_NO_ANSWER: 'testNoAnswer',
  TEST_NO_PROBLEM: 'testNoProblem'
}
