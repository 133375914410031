let token = ''

export function setToken(_token) {
  token = _token
}

export function getToken() { return token }

export function fetchWithCredentials(url) {
  if (token && token !== 'undefined') {
    url += `?token=${token}`
    return fetch(url, {
      credentials: 'include',
      referrerPolicy: 'unsafe-url'
    })
  }
  try {
    return fetch(url, {
      credentials: 'include',
      referrerPolicy: 'unsafe-url'
    })
  } catch (e) {
    console.error(e)
    //
  }
}

export const image = id => `${process.env.REACT_APP_API_URI}/files/blobs/converted/700x/${id}` // ?token=${getToken()}`
export const ssdbImage = name => `${process.env.REACT_APP_API_URI}/files/blobs/converted/350x/name/${name}` // ?token=${getToken()}`
export const imageWithoutToken = id => `${process.env.REACT_APP_API_URI}/files/blobs/converted/700x/${id}`

// SSDB image sample: http://127.0.0.1:8080/api/v1/files/blobs/converted/350x/name/2TBSX01611A01.jpg