const conversionFunctions = {
  MPa: {
    Bar: val => val * 10,
    PSI: val => val * 145.038,
    'kgf/cm2': val => val * 10.1972,
  },
  kPa: {
    Bar: val => val / 100,
    PSI: val => val * 145.0377 / 1000,
    'kgf/cm2': val => val * 10.1972 / 1000,
  },
  mm: {
    in: val => val / 25.4,
  },
  'L/min': {
    'U.S. gals/min': val => val * 0.264172,
    'Imp. gals/min': val => val * 0.22,
  },
}

export const isValueOk = (value, min, max, strictValue, baseType, type) => {
  if (value == null) return

  let convertedMin = parseFloat(min)
  let convertedMax = parseFloat(max)
  let convertedStrictValue = parseFloat(strictValue)

  if (baseType in conversionFunctions && type in conversionFunctions[baseType]) {
    const convert = conversionFunctions[baseType][type]
    convertedMin = convert(convertedMin)
    convertedMax = convert(convertedMax)
    convertedStrictValue = convert(convertedStrictValue)
  }

  const marginOfConvertError = convertedStrictValue * 0.01 // 1% of the strict value
  const lowerBound = convertedStrictValue - marginOfConvertError
  const upperBound = convertedStrictValue + marginOfConvertError

  const result = {
    convertedStrictValue,
    isValid: (value >= lowerBound && value <= upperBound) || (value >= convertedMin && value <= convertedMax)
  }

  console.log('isValueOk', result)
  return result
}
